import React from "react";
import "./index.style.scss";
import { Tabs } from "antd";
import PersonalInfo from "./PersonalInfo";
import ChangePassword from "./ChangePassword";

import { HiUser } from "react-icons/hi";
import { AiFillLock } from "react-icons/ai";

// import accountData from '../../../@crema/services/db/extraPages/account';
// import IntlMessages from "../../../@crema/utility/IntlMessages";

const UserProfile = () => {

   
  const TabPane = Tabs.TabPane;
  return (
    <div className="user-profile-container">
      <Tabs
        className="user-profile-tabs"
        defaultActiveKey="1"
        tabPosition="left"
      >
        <TabPane
          tab={
            <span className="user-profile-icon">
              <HiUser className="icon" />
              <span>Personal Info </span>
            </span>
          }
          key="1"
        >
          <PersonalInfo />
        </TabPane>
        <TabPane
          tab={
            <span className="user-profile-icon">
              <AiFillLock className="icon" />
              <span>Change Password </span>
            </span>
          }
          key="2"
        >
          <ChangePassword />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserProfile;
