const languageData = [
  {
    languageId: "UZBEK",
    locale: "uz",
    name: "Uzbek",
   },
  {
    languageId: "RUSSIAN",
    locale: "ru",
    name: "Russian",
   },
];
export default languageData;
