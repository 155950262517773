import { createContext, useContext, useEffect, useState } from "react";
import jwtAxios, { setAuthToken } from "./jwt-api";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../i18n/provider";
import { Button, notification } from "antd";
import useMessage from "antd/es/message/useMessage";

const JWTAuthContext = createContext({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

const JWTAuthActionsContext = createContext();

export const useAuthDatas = () => useContext(JWTAuthContext);
export const useAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const navigate = useNavigate();
  const { language } = useLocale();
  const [messageApi, contextHolder] = useMessage();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setUserData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get("/employees/profile", {
          params: { language: localStorage.getItem("language") || language },
        })
        .then(({ data }) => {
          setUserData({
            user: data,
            isLoading: false,
            isAuthenticated: true,
          });
        })
        .catch(() => {
          setUserData({
            user: null,
            isLoading: false,
            isAuthenticated: false,
          });
        });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ phone, password, rememberMe }) => {
    try {
      setUserData((prev) => ({ ...prev, isLoading: true }));

      const { data } = await jwtAxios.post(`auth/login?lang=${language}`, {
        phone: phone.replace(/\D/g, ""),
        password,
        restaurant_id: 1,
      });


      setAuthToken(data.access_token);
      const res = await jwtAxios.get("/employees/profile", {
        params: { language },
      });

      setUserData({ user: res?.data, isAuthenticated: true, isLoading: false });

      // rememberMe || localStorage.removeItem("token");
      navigate("/main/reservation");
    } catch (error) {
      messageApi.error(error?.response?.data?.message);

      setUserData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const logout = async () => {
    setAuthToken();
    setUserData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    navigate("/");
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...userData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
          setUserData,
        }}
      >
        {contextHolder}
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
