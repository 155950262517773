import React from "react";
import "./index.styles.scss";
import { Button, Checkbox, Form, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import translate from "./../../i18n/translate";
import { useAuthActions } from "../../auth/JWTAuthProvider";
import ReactInputMask from "react-input-mask";
// import image from "../../assets/izumrud/login-bg.jpg";
// import logo from "../../assets/izumrud/logo.png";

export default function SignIn() {
  const { signInUser } = useAuthActions();
  const { messages } = useIntl();
  return (
    <div className="signin-page-container">
      <div className="signin-content">
        <img src={"/files/wedding/izumrud/logo.png"} alt="" />
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={signInUser}
        >
          <Form.Item
            name="phone"
            className="form-field"
            rules={[
              { required: true, message: translate("input.required.phone") },
              {
                validator(_, data) {
                  const phone = data.replace(/\D/g, "");
                  if (phone && phone.length !== 12 && phone.length !== 3) {
                    return Promise.reject(messages["input.error.phone-length"]);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <ReactInputMask
              mask="+\9\98 (99) 999-99-99"
              placeholder="+998 (99) 999-99-99"
              name="phone"
              id="phone"
              className={"antd-input-style"}
              type="tel"
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="form-field"
            rules={[
              { required: true, message: translate("input.required.password") },
            ]}
          >
            <Input.Password placeholder={messages["common.password"]} />
          </Form.Item>

          {/* <div className="rememberMe">
            <Checkbox onChange={onRememberMe}>
              <FormattedMessage id="common.rememberMe" />
            </Checkbox>
          </div> */}

          <div className="form-btn-field form-field-action">
            <Button
              type="primary"
              htmlType="submit"
              className="sign-btn"
              style={{ width: "100%" }}
            >
              <FormattedMessage id="button.login" />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
