import { Button } from "antd";
import { Header } from "antd/es/layout/layout";
import React from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";

// import logo from "../../../assets/izumrud/logo.png";
import LanguageSwitcher from "./../../../components/LanguageSwitcher/index";

export default function Navbar({ collapsed, setCollapsed }) {
  return (
    <Header className={styles.header}>
      {setCollapsed && (
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          className={styles.button}
        />
      )}
      <img src={"/files/wedding/izumrud/logo.png"} alt="" />

      <LanguageSwitcher></LanguageSwitcher>
    </Header>
  );
}
