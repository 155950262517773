import { Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../FormWrapper";
import jwtAxios from "../../../auth/jwt-api";
import useIntl from "react-intl/lib/src/components/useIntl";
import { useLocale } from "../../../i18n/provider";
import { useQuery } from "react-query";
import ServiceSelector from "../../../layouts/components/ServiceSelector";
import ReactInputMask from "react-input-mask";

function EditRestaurant() {
  const [{ restaurant, isLoading }, setFood] = useState({
    restaurant: null,
    isLoading: true,
  });
  const { messages } = useIntl();
  const { language } = useLocale();
  const navigate = useNavigate();
  const [selectedServices, setSelectedServices] = useState([]);

  const { data: services } = useQuery("services", () =>
    jwtAxios(`/services?lang=${language}`)
  );

  useEffect(() => {
    (async () => {
      try {
        const data = await jwtAxios.get(`/restaurants`);

        setSelectedServices(data.data.services.map((service) => service.id));
        setFood({ restaurant: data.data, isLoading: false });
      } catch (error) {
        console.log({ error });
        setFood({ restaurant: null, isLoading: false });
      }
    })();
  }, []);

  if (isLoading) {
    return "Loading...";
  }

  const onFinish = async (data) => {
    data.phone = data.phone?.replace(/\D/g, "");
    data.service_ids = selectedServices;
    // .map(id => String(id));
    try {
      await jwtAxios.patch(`restaurants?lang=${language}`, data);

      message.success("Successfully updated");

      // navigate("/main/table?pageId=menus");
    } catch (error) {
      message.error(error.response?.data?.message);

      console.log({ error });
    }
  };
  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const initialValues = restaurant || {};

  return (
    <FormWrapper
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="form-grid">
        <Form.Item
          name={"name"}
          label={messages["common.name"]}
          rules={[{ required: true, message: messages["input.required.name"] }]}
        >
          <Input placeholder={`${messages["common.name"]}...`} block />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: messages["input.required.phone"] },
            {
              validator(_, data) {
                const phone = data.replace(/\D/g, "");
                if (phone && phone.length !== 12 && phone.length !== 3) {
                  return Promise.reject(messages["input.error.phone-length"]);
                }
                return Promise.resolve();
              },
            },
            
          ]}
          label={messages["common.phone"]}
        >
          <ReactInputMask
            style={{
              width: "100%",
            }}
            mask="+\9\98 (99) 999-99-99"
            placeholder="+998 (99) 999-99-99"
            name="phone"
            id="phone"
            className={"antd-input-style"}
            type="tel"
          />
        </Form.Item>

        <ServiceSelector
          services={services?.data?.entity}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
        ></ServiceSelector>
      </div>

      {/* option.label.toLowerCase().includes(input.toLowerCase()) */}
    </FormWrapper>
  );
}

export default EditRestaurant;
