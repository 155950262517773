import React, { createContext, Fragment, useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import messages from "./messages";
import { LOCALES } from "./locales";

const LangContext = createContext({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

export const useLocale = () => useContext(LangContext);

export default function Provider({ children, defaultLocale = LOCALES.UZBEK }) {
  const [language, setLanguage] = useState(defaultLocale);
   
  return (
    <LangContext.Provider
      value={{
        setLanguage,
        language,
      }}
    >
    
      <IntlProvider
        locale={language}
        textComponent={Fragment}
        messages={messages[language]}
      >
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
}
