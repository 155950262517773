import React, { useState } from "react";
import "./index.styles.scss";
import { Checkbox, Input } from "antd";

export default function ServiceSelector({
  selectedServices,
  setSelectedServices,
  services = [],
}) {
  const [filteredServices, setFilteredServices] = useState(services);

  return (
    <div className="service-selector-container">
      <p>Restarant servislari:</p>
      <Input
        placeholder="Search"
        onChange={(e) => {
          setFilteredServices(
            services.filter((service) =>
              service.name.toLowerCase().includes(e.target.value?.toLowerCase())
            )
          );
        }}
      ></Input>
      {filteredServices[0] && (
        <div className="service-container">
          {filteredServices?.map((service) => (
            <div
              className="service-card"
              onClick={() => {
                if (selectedServices.includes(service.id)) {
                  setSelectedServices(
                    selectedServices.filter((id) => id !== service.id)
                  );
                } else {
                  setSelectedServices([...selectedServices, service.id]);
                }
              }}
            >
              <Checkbox
                checked={selectedServices.includes(service.id)}
              ></Checkbox>
              {service.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
