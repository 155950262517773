import moment from "moment";
import React, { useState } from "react";
import {
  Calendar,
  momentLocalizer,
  dateFnsLocalizer,
} from "react-big-calendar";
import { ru } from "date-fns/locale";
import { uz } from "date-fns/locale";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "./index.style.scss";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import { LOCALES } from "../../i18n";
import { useLocale } from "../../i18n/provider";
import { useLocation, useNavigate } from "react-router-dom";
import { useUrlSearchParams } from "use-url-search-params";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";

const locales = { uz, ru };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  [LOCALES.RUSSIAN]: {
    week: "Неделя",
    work_week: "Рабочая неделя",
    day: "День",
    month: "Месяц",
    previous: <GrLinkPrevious />,
    next: <GrLinkNext />,
    today: "Сегодня",
    agenda: "Повестка дня",
    showMore: (total) => `+${total} ещё`,
    noEventsInRange: "Нет бронирований для этого диапазона!",
  },
  [LOCALES.UZBEK]: {
    week: "Hafta",
    work_week: "Ish haftasi",
    day: "Kun",
    month: "Oy",
    previous: <GrLinkPrevious />,
    next: <GrLinkNext />,
    today: "Bugun",
    agenda: "Kun tartibi",
    noEventsInRange: "Bron mavjud emas!",

    showMore: (total) => `+${total} ta yana`,
  },
};

const ReservationCalendar = () => {
  const { language } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useUrlSearchParams();

  const { data: reservations } = useQuery("reservations", () =>
    jwtAxios(`/reservations?lang=${language}`)
  );

  const reservationEvents = reservations?.data?.entity?.map((reservation) => ({
    title: reservation.customer_name,
    start: new Date(reservation.start_time),
    end: new Date(reservation.end_time),
    allDay: false,
    id: reservation.id,
  }));

  return (
    <div className="reservation-calendar-container">
      <Calendar
        localizer={localizer}
        style={{ height: "calc(100vh - 64px - 30px - 30px)", width: "100%" }}
        events={reservationEvents}
        culture={language}
        messages={messages[language]}
        onSelectEvent={(e) => {
          navigate(
            location.pathname === "/main/reservation"
              ? `/main/update/reservation/${e.id}`
              : `/webapp/update/reservation/${e.id}`
          );
        }}
        onSelectSlot={function (slotInfo) {
          if (!searchParams.view || searchParams.view === "month") {
            return;
          }
          navigate(
            location.pathname === "/main/reservation"
              ? `/main/create/reservation?start_time=${slotInfo.start.toISOString()}&end_time=${slotInfo.end.toISOString()}`
              : `/webapp/create/reservation?start_time=${slotInfo.start.toISOString()}&end_time=${slotInfo.end.toISOString()}`
          );
        }}
        selectable
        view={searchParams.view || "month"}
        onView={(view) => setSearchParams({ ...searchParams, view })}
      />
    </div>
  );
};

export default ReservationCalendar;
