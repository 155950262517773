import { Form, Input,   message } from "antd";
 import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../FormWrapper";
import jwtAxios from "../../../auth/jwt-api";
import useIntl from "react-intl/lib/src/components/useIntl";
 import { useLocale } from "../../../i18n/provider";

function Food() {
  const [{ food, isLoading }, setFood] = useState({
    food: null,
    isLoading: true,
  });
  const { messages } = useIntl();
  const { language } = useLocale();
  const navigate = useNavigate();
  const { id } = useParams();

 
  useEffect(() => {
    if (!id) {
      return setFood({ food: null, isLoading: false });
    }
    (async () => {
      try {
        const currentUser = await jwtAxios.get(`/foods/${id}`);
        setFood({ food: currentUser.data, isLoading: false });
      } catch (error) {
        console.log({ error });
        setFood({ food: null, isLoading: false });
      }
    })();
  }, [id]);

  if (isLoading) {
    return "Loading...";
  }

  const onFinish = async (data) => {
     try {
      await jwtAxios[id ? "patch" : "post"](
        `foods${id ? `/${id}` : ""}?lang=${language}`,
        data
      );

      message.success(id ? "Successfully updated" : "Successfully posted");

      navigate("/main/table?pageId=foods");
    } catch (error) {
      message.error(error.response?.data?.message);

      console.log({ error });
    }
  };
  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const initialValues = food || {};

  return (
    <FormWrapper
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
        layout="vertical"
    >
      <div className="form-grid">
        <Form.Item
          name={"name"}
          label={messages["common.name"]}
          rules={[
            { required: true, message: messages["input.required.name"] },
          ]}
        >
          <Input placeholder={`${messages["common.name"]}...`} block />
        </Form.Item>
 
      </div>
    </FormWrapper>
  );
}

export default Food;
