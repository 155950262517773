import React, { useState } from "react";
import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import useIntl from "react-intl/lib/src/components/useIntl";
import { useForm } from "antd/lib/form/Form";
import jwtAxios from "../../../auth/jwt-api";

const ChangePassword = () => {
  const { messages } = useIntl();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await jwtAxios.put(`/auth/change-password`, values);
      notification.success({
        message: messages["notification.success.change.password"],
      });
      form.resetFields();
    } catch (error) {
      notification.error({
        message: messages["notification.error.change.password"],
        description: error.response?.data?.error,
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className="user-profile-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      form={form}
      onFinishFailed={onFinishFailed}
    >
      <h3 className="user-profile-form-title">Change password </h3>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Old password is required",
              },
            ]}
          >
            <Input.Password placeholder={"Old passowrd"} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} />
        <Col xs={24} md={12}>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "New password required",
              },
            ]}
          >
            <Input.Password placeholder={"New password"} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Confirm password is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The Confirm Password that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder={"Confirm password"} />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <Form.Item shouldUpdate  >
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
              <Button htmlType="cancel">Cancel</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePassword;
