import { Routes, Route } from "react-router-dom";

function useRouteGenerator(routes) {
  return (
    <Routes>
      {routes.map((route) => {
        if (Array.isArray(route.path)) {
          return route.path.map((path) => (
            <Route {...route} path={path} key={route.url}></Route>
          ));
        }
        return <Route {...route} key={route.url}></Route>;
      })}
    </Routes>
  );
}

export default useRouteGenerator;
