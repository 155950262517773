import React, { useState } from "react";
import { useAuthDatas } from "../../../../auth/JWTAuthProvider";

const PersonalInfo = () => {
 
  return (
    <div>
      <h1>Fazliddin Mirzaqosimov</h1>
    </div>
  );
};

export default PersonalInfo;
