export const API_URL = process.env.REACT_APP_API_URL;
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION;


 const restaurants = {
  izumrud: 1,
};

export const RESTAURANT_NAME = window.location.hostname?.split(".")[0];
export const RESTAURANT_ID = restaurants[RESTAURANT_NAME];
