import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  TimePicker,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../FormWrapper";
import jwtAxios from "../../../auth/jwt-api";
import useIntl from "react-intl/lib/src/components/useIntl";
import ReactInputMask from "react-input-mask";
import { useLocale } from "../../../i18n/provider";
import { useUrlSearchParams } from "use-url-search-params";
import { format, parse } from "date-fns";
import { ru, uz } from "date-fns/locale";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import TextArea from "antd/es/input/TextArea";

function Admin() {
  const [{ reservation, isLoading }, setUser] = useState({
    reservation: null,
    isLoading: true,
  });
  const [currency, setCurrency] = useState("uzs");
  const { messages } = useIntl();
  const { language } = useLocale();
  const navigate = useNavigate();
  const { id } = useParams();
  const [params] = useUrlSearchParams();

  console.log(reservation);

  const { data: services } = useQuery("restaurant-services", () =>
    jwtAxios(`/restaurants/services?lang=${language}`)
  );
  const { data: foods } = useQuery("restaurant-foods", () =>
    jwtAxios(`/foods?lang=${language}`)
  );

  useEffect(() => {
    if (!id) {
      return setUser({ reservation: null, isLoading: false });
    }
    (async () => {
      try {
        const res = await jwtAxios.get(`/reservations/${id}`);
        setUser({ reservation: res.data, isLoading: false });
      } catch (error) {
        console.log({ error });
        setUser({ reservation: null, isLoading: false });
      }
    })();
  }, [id]);

  if (isLoading) {
    return "Loading...";
  }

  const onFinish = async (data) => {
    data.customer_phone = data.customer_phone?.replace(/\D/g, "") || undefined;

    if (params.start_time && params.end_time && !id) {
      const originalStartTime = new Date(params.start_time);
      const originalEndTime = new Date(params.end_time);

      originalStartTime.setHours(
        data.time[0].hour(),
        data.time[0].minute(),
        data.time[0].second()
      );

      originalEndTime.setHours(
        data.time[1].hour(),
        data.time[1].minute(),
        data.time[1].second()
      );

      data.start_time = originalStartTime.toISOString();
      data.end_time = originalEndTime.toISOString();
    } else if (reservation.start_time && reservation.end_time) {
      const originalStartTime = new Date(reservation.start_time);
      const originalEndTime = new Date(reservation.end_time);

      originalStartTime.setHours(
        data.time[0].hour(),
        data.time[0].minute(),
        data.time[0].second()
      );

      originalEndTime.setHours(
        data.time[1].hour(),
        data.time[1].minute(),
        data.time[1].second()
      );

      data.start_time = originalStartTime.toISOString();
      data.end_time = originalEndTime.toISOString();
    }

    data.service_ids = data.service_ids || [];
    data.food_ids = data.food_ids || [];

    try {
      await jwtAxios[id ? "patch" : "post"](
        `reservations${id ? `/${id}` : ""}?lang=${language}`,
        data
      );

      message.success(id ? "Successfully updated" : "Successfully posted");

      navigate("/main/reservation");
    } catch (error) {
      message.error(error.response?.data?.message);

      console.log({ error });
    }
  };
  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const initialValues = reservation
    ? {
        ...reservation,
        time: [
          dayjs(format(new Date(reservation.start_time), "HH:mm"), "HH:mm"),
          dayjs(format(new Date(reservation.end_time), "HH:mm"), "HH:mm"),
        ],
      }
    : {
        time: [
          dayjs(format(new Date(params.start_time), "HH:mm"), "HH:mm"),
          dayjs(format(new Date(params.end_time), "HH:mm"), "HH:mm"),
        ],
        currency: currency,
        total_people: 200,
      };
  return (
    <FormWrapper
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      onCancel={()=>{
        navigate("/main/reservation?view=month");
      }}
    >
      <br />
      <h2>
        Reservation for{" "}
        {reservation
          ? format(reservation.start_time, "dd-MMMM yyyy", {
              locale: { ru, uz }[language],
            })
          : format(params.start_time, "dd-MMMM yyyy", {
              locale: { ru, uz }[language],
            })}
      </h2>
      <br />

      <div className="form-grid">
        <Form.Item
          name={"time"}
          label={messages["common.start_time"]}
          rules={[
            {
              required: true,
              message: messages["input.required.start_time"],
            },
          ]}
        >
          <TimePicker.RangePicker
            style={{
              width: "100%",
            }}
            changeOnScroll
            needConfirm={false}
            format="HH:mm"
            placeholder={["Start time", "End time"]}
          />
        </Form.Item>
        <Form.Item
          name={"customer_name"}
          label={messages["common.customer_name"]}
          rules={[
            {
              required: true,
              message: messages["input.required.customer_name"],
            },
          ]}
        >
          <Input placeholder={`${messages["common.customer_name"]}...`} block />
        </Form.Item>
        <Form.Item
          name="customer_phone"
          label={messages["common.customer_phone"]}
          rules={[
            {
              validator(_, data) {
                const phone = data?.replace(/\D/g, "");
                if (phone && phone.length !== 12 && phone.length !== 3) {
                  return Promise.reject(messages["input.error.phone-length"]);
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ReactInputMask
            style={{
              width: "100%",
            }}
            mask="+\9\98 (99) 999-99-99"
            placeholder="+998 (99) 999-99-99"
            name="customer_phone"
            id="customer_phone"
            className={"antd-input-style"}
            type="tel"
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={"total_people"}
              label={messages["common.total_people"]}
              rules={[
                {
                  required: true,
                  message: messages["input.required.total_people"],
                },
              ]}
            >
              <InputNumber
                placeholder={`${messages["common.total_people"]}...`}
                style={{ width: "100%" }}
                block
              />
            </Form.Item>{" "}
          </Col>
          <Col span={12}>
            <Form.Item name={"currency"} label={messages["common.currency"]}>
              <Select
                placeholder={`${messages["common.currency"]}...`}
                block
                options={[
                  { label: "So'm", value: "uzs" },
                  { label: "Dollar", value: "usd" },
                ]}
                onChange={(currency) => {
                  setCurrency(currency);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={"total_budget"}
              label={messages["common.total_budget"]}
            >
              <InputNumber
                type="number"
                placeholder={`${messages["common.total_budget"]}...`}
                addonAfter={currency}
                max={9_000_000_000}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"individual_package"}
              label={messages["common.individual_package"]}
            >
              <InputNumber
                type="number"
                placeholder={`${messages["common.individual_package"]}...`}
                addonAfter={currency}
                max={9_000_000_000}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={"description"} label={messages["common.description"]}>
          <TextArea
            placeholder={`${messages["common.description"]}...`}
            block
          />
        </Form.Item>
      </div>
    </FormWrapper>
  );
}

export default Admin;
