import React from "react";
import { mainRoutes } from "../pages/main";
import { authRoutes } from "../pages/auth";
import { useAuthDatas } from "../auth/JWTAuthProvider";
import useRouteGenerator from "../shared/utils/routeGenerator";
import { Navigate, useLocation } from "react-router-dom";
import MainLayout from "./MainLayout";
import Loading from "./components/Loading";
import { errorRoutes } from "../pages/error";
import WebappLayout from "./WebappLayout";

function AppLayout() {
  const { isAuthenticated, isLoading } = useAuthDatas();

  const { pathname } = useLocation();
  const isMainLayout =
    pathname.startsWith("/main") ||
    (isAuthenticated && pathname.startsWith("/error"));

  const isWebappLayout = pathname.startsWith("/webapp");

  const routes = useRouteGenerator(
    isAuthenticated
      ? [
          ...errorRoutes,
          ...mainRoutes,
          ...authRoutes.map((route) => ({
            path: route.path,
            element: <Navigate to={"/main/reservation"}></Navigate>,
          })),
          {
            path: "/",
            element: <Navigate to="/main/reservation" />,
          },
          {
            path: "*",
            element: <Navigate to="/error/not-found" />,
          },
        ]
      : [
          ...errorRoutes,
          ...authRoutes,
          ...mainRoutes.map((route) => ({
            path: route.path,
            element: <Navigate to={"/auth/signin"}></Navigate>,
          })),
          {
            path: "/",
            element: <Navigate to="/auth/signin" />,
          },
          {
            path: "*",
            element: <Navigate to="/error/not-found" />,
          },
        ]
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (!isAuthenticated) {
    return <div> {routes}</div>;
  }

 
  return isMainLayout ? (
    <MainLayout>{routes}</MainLayout>
  ) : isWebappLayout ? (
    <WebappLayout>{routes}</WebappLayout>
  ) : (
    <div> {routes}</div>
  );
}

export default AppLayout;
