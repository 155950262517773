import React from "react";
import languageData from "./data";

import { Dropdown, Menu } from "antd";
import { IoLanguageOutline } from "react-icons/io5";
import { useLocale } from "../../i18n/provider";
import "./index.style.scss";
import { LOCALES } from "../../i18n/locales";

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLocale();

  const menu = (
    <Menu id="language-switcher">
      {languageData.map((language, index) => (
        <Menu.Item
          key={index}
          onClick={() =>
            LOCALES[language.languageId] &&
            setLanguage(LOCALES[language.languageId])
          }
        >
              <h4>{language.name}</h4>
         </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayStyle={{ zIndex: 1052 }}
      >
    <div className="language-switcher-button">

          <IoLanguageOutline />
    </div>
      </Dropdown>
    </>
  );
};

export default LanguageSwitcher;
