import { render } from "@testing-library/react";
import { Button, Popconfirm, Space } from "antd";
import { CiEdit } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ExclamationCircleTwoTone, QuestionCircleOutlined } from "@ant-design/icons";
import translate from "./../../../i18n/translate";

export const getColumns = () => {
  const noColumn = {
    title: "NO",
    dataIndex: "no",
    key: "no",
    width: 50,
  };

  return {
    employees: [
      // noColumn,
      {
        title: "Fullname",
        dataIndex: "full_name",
        key: "full_name",
      },
      // {
      //   title: "Phone",
      //   dataIndex: "phone",
      //   key: "phone",
      // },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
    ],
    foods: [
      // noColumn,
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
    ],
    menus: [
      // noColumn,
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
    ],
    "service-categories": [
      // noColumn,
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
    ],
  };
};

export const getActions = ({ idName = "id", page, handleDelete }) => ({
  title: "Actions",
  dataIndex: idName,
  key: "link",
  width: 100,
  fixed: "right",
  render(id, data) {
    return (
      <div>
        <Space>
          {page && (
            <Link to={`/main/${page}/${id}`}>
              <Button size="small">
                <CiEdit />
              </Button>
            </Link>
          )}

          <Popconfirm
            title={translate("common.delete")}
            description={translate("confirm.delete")}
            onConfirm={() => handleDelete(id, data)}
            okText={translate("common.yes")}
            cancelText={translate("common.no")}
            placement="topLeft"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            {/* confirm({
      title: t("common.delete"),
      icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      async onOk() {
        
      },
    }); */}
            <Button size="small" type="primary" danger>
              <FaRegTrashAlt />
            </Button>
          </Popconfirm>
        </Space>
      </div>
    );
  },
});
