import { UserOutlined } from "@ant-design/icons";
import { FaDrum } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { IoFastFoodOutline, IoRestaurantOutline } from "react-icons/io5";
import { MdOutlineMenuBook } from "react-icons/md";
import { TbReport } from "react-icons/tb";

const menuItems = [
  {
    key: "reservation",
    icon: <TbReport />,
    link: "main/reservation",
    messageId: "Reservations",
  },
  {
    key: "employees",
    icon: <GrUserAdmin />,
    link: "main/table?pageId=employees",
    messageId: "Employees",
  },

  {
    key: "foods",
    icon: <IoFastFoodOutline />,
    link: "main/table?pageId=foods",
    messageId: "Foods",
  },

  {
    key: "menus",
    icon: <MdOutlineMenuBook />,
    link: "main/table?pageId=menus",
    messageId: "Menus",
  },

  {
    key: "service-categories",
    icon: <FaDrum />,
    link: "main/table?pageId=service-categories",
    messageId: "Services",
  },
  {
    key: "edit-restaurant",
    icon: <IoRestaurantOutline />,
    link: "/main/edit-restaurant",
    messageId: "Edit Restaurant",
  },
]; ;

export default menuItems;
