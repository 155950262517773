import React, { useRef, useState } from "react";
import { useUrlSearchParams } from "use-url-search-params";
import { useAuthDatas } from "../../../auth/JWTAuthProvider";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import getUrl from "../../../shared/utils/getUrl";
import { Button, Input, message, Space, Table } from "antd";
import { useIntl } from "react-intl";
import { getActions, getColumns } from "./columns";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwt-api";
import confirm from "antd/es/modal/confirm";

export default function TablePage() {
  const [searchParams, setSearchParams] = useUrlSearchParams();
  const page = searchParams.pageId;
  const location = useLocation();
  const { messages, formatMessage } = useIntl();
  const t = (id) => formatMessage({ id }) || id;

  const debounceRef = useRef(null);
  const inputRef = useRef("");

  const [pagination, setPagination] = useState({
    current: searchParams.page || 1,
    pageSize: searchParams.rows || 20,
  });

  const apiParams = {
    ...searchParams,
    "page[offset]": (pagination.current - 1) * pagination.pageSize,
    "page[limit]": pagination.pageSize,
  };

  const api = getUrl(`/${page}?`, apiParams);

  const { data, isLoading, refetch } = useQuery(api, () => jwtAxios.get(api));

  const columns = getColumns()[page];

  //delete row
  const handleDelete = async (id) => {

     try {
          await jwtAxios.delete(`/${page}/${id}`);
          message.success(messages["notification.success.delete"]);
          refetch();
        } catch (error) {
          message.error(error.response?.data?.message);
          console.log(error);
        }


   
  };

  return (
    <div>
      <h1>{page}</h1>
      <br />
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: 5,
        }}
      >
        <Input
          ref={inputRef}
          defaultValue={searchParams.s}
          autoComplete={"one-time-code"}
          placeholder={t("common.search")}
          onChange={(e) => {
            const value = e.currentTarget.value;

            clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(() => {
              setPagination({ ...pagination, current: 1 });
              setSearchParams({ ...searchParams, s: value });
            }, 1000);
          }}
          size="small"
        />
        <Link to={`/main/${page}`}>
          <Button type="primary" size="small">
            Add
          </Button>
        </Link>{" "}
      </div>
      <br />
      <Table
        loading={isLoading}
        columns={columns?.concat(getActions({ page, handleDelete }))}
        dataSource={data?.data?.entity}
        size="small"
      ></Table>
    </div>
  );
}
