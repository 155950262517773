import React, { useState } from "react";
import { Button, Layout, Menu, theme } from "antd";
import { Link } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import "./index.scss";

const { Header, Sider, Content } = Layout;
const WebappLayout = ({ children }) => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Navbar />
      <Content
        style={{
          overflow: "auto",
        }}
      >
        <div
          style={{
            minWidth: 280,
            padding: "clamp(10px,3%,30px)",
          }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
};
export default WebappLayout;
