import React, { useState } from "react";
import { Button, Layout, Menu, theme } from "antd";
import { Link } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import "./index.scss"

const { Header, Sider, Content } = Layout;
const MenuLayout = ({children}) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 500);

  return (
    <Layout style={{ height: "100vh" }}>
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            overflow: "auto",
          }}
        >
          <div
            style={{
              minWidth: 280,
              padding: "clamp(10px,3%,30px)",
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MenuLayout;
