import { Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../FormWrapper";
import jwtAxios from "../../../auth/jwt-api";
import useIntl from "react-intl/lib/src/components/useIntl";
import { useLocale } from "../../../i18n/provider";
import { useQuery } from "react-query";

function Menu() {
  const [{ menu, isLoading }, setFood] = useState({
    menu: null,
    isLoading: true,
  });
  const { messages } = useIntl();
  const { language } = useLocale();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: foods } = useQuery("restaurant-foods", () =>
    jwtAxios(`/foods?lang=${language}`)
  );
 
  useEffect(() => {
    if (!id) {
      return setFood({ menu: null, isLoading: false });
    }
    (async () => {
      try {
        const currentUser = await jwtAxios.get(`/menus/${id}`);
        setFood({ menu: currentUser.data, isLoading: false });
      } catch (error) {
        console.log({ error });
        setFood({ menu: null, isLoading: false });
      }
    })();
  }, [id]);

  if (isLoading) {
    return "Loading...";
  }

  const onFinish = async (data) => {
    try {
      await jwtAxios[id ? "patch" : "post"](
        `menus${id ? `/${id}` : ""}?lang=${language}`,
        data
      );

      message.success(id ? "Successfully updated" : "Successfully posted");

      navigate("/main/table?pageId=menus");
    } catch (error) {
      message.error(error.response?.data?.message);

      console.log({ error });
    }
  };
  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const initialValues = menu
    ? { ...menu, food_ids: menu.foods.map((food) => food.id) }
    : {};

  return (
    <FormWrapper
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="form-grid">
        <Form.Item
          name={"name"}
          label={messages["common.name"]}
          rules={[{ required: true, message: messages["input.required.name"] }]}
        >
          <Input placeholder={`${messages["common.name"]}...`} block />
        </Form.Item>

        <Form.Item
          name={"food_ids"}
          label={messages["common.foods"]}
          rules={[
            { required: true, message: messages["input.required.foods"] },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder={messages["common.foods"]}
            // defaultValue={["a10", "c12"]}
            options={foods?.data?.entity?.map((food) => ({
              value: food.id,
              label: food.name,
            }))}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </div>
    </FormWrapper>
  );
}

export default Menu;
