import { Form, Input, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../FormWrapper";
import jwtAxios from "../../../auth/jwt-api";
import useIntl from "react-intl/lib/src/components/useIntl";
import ReactInputMask from "react-input-mask";
import { useLocale } from "../../../i18n/provider";

function Admin() {
  const [{ user, isLoading }, setUser] = useState({
    user: null,
    isLoading: true,
  });
  const { messages } = useIntl();
  const { language } = useLocale();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return setUser({ user: null, isLoading: false });
    }
    (async () => {
      try {
        const currentUser = await jwtAxios.get(`/employees/${id}`);
        setUser({ user: currentUser.data, isLoading: false });
      } catch (error) {
        console.log({ error });
        setUser({ user: null, isLoading: false });
      }
    })();
  }, [id]);

  if (isLoading) {
    return "Loading...";
  }

  const onFinish = async (data) => {
    data.phone = data.phone.replace(/\D/g, "");
    try {
      await jwtAxios[id ? "patch" : "post"](
        `employees${id ? `/${id}` : ""}?lang=${language}`,
        data
      );

      message.success(id ? "Successfully updated" : "Successfully posted");

      navigate("/main/table?pageId=employees");
    } catch (error) {
      message.error(error.response?.data?.message);

      console.log({ error });
    }
  };
  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const initialValues = user ? { ...user, password: null } : {};

  return (
    <FormWrapper
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="form-grid">
        <Form.Item
          name={"full_name"}
          label={messages["common.full_name"]}
          rules={[
            { required: true, message: messages["input.required.full_name"] },
          ]}
        >
          <Input placeholder={`${messages["common.full_name"]}...`} block />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            { required: true, message: messages["input.required.phone"] },
          ]}
          label={messages["common.phone"]}
        >
          <ReactInputMask
            style={{
              width: "100%",
            }}
            mask="+\9\98 (99) 999-99-99"
            placeholder="+998 (99) 999-99-99"
            name="phone"
            id="phone"
            className={"antd-input-style"}
            type="tel"
          />
        </Form.Item>

        <Form.Item
          label={messages["common.role"]}
          name="role"
          rules={[{ required: true, message: messages["input.required.role"] }]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            placeholder={`${messages["common.role"]}...`}
            options={[
              {
                label: "Owner",
                value: "owner",
              },
              {
                label: "Manager",
                value: "manager",
              },
              {
                label: "Chef",
                value: "viewer",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={messages["common.password"]}
          name="password"
          rules={[
            { required: true, message: messages["input.required.password"] },
            {
              validator(_, data) {
                if (!data || data.length > 7) {
                  return Promise.resolve();
                }
                return Promise.reject(messages["input.error.password<8"]);
              },
            },
          ]}
        >
          <Input.Password placeholder={`${messages["common.password"]}...`} />
        </Form.Item>
      </div>
    </FormWrapper>
  );
}

export default Admin;
