import { Navigate } from "react-router-dom";
import Admin from "./forms/Employees";
import Food from "./forms/Food";
import Menu from "./forms/Menu";
import Service from "./forms/EditRestaurant";
import ServiceGroup from "./forms/ServiceGroup";
import Table from "./Table";
import UserProfile from "./Profile";
import Reservation from "./Reservation/Reservation";
import CreateReservation from "./Reservation/CreateReservation";
import EditRestaurant from "./forms/EditRestaurant";
import "./index.styles.scss";

export const mainRoutes = [
  {
    path: "/main/table",
    element: <Table />,
  },

  {
    path: ["/main/employees", "/main/employees/:id"],
    element: <Admin />,
  },

  {
    path: ["/main/foods", "/main/foods/:id"],
    element: <Food />,
  },

  {
    path: ["/main/menus", "/main/menus/:id"],
    element: <Menu />,
  },
  {
    path: "/main/service",
    element: <Service />,
  },
  {
    path: ["/main/service-categories", "/main/service-categories/:id"],
    element: <ServiceGroup />,
  },
  {
    path: "/main/profile",
    element: <UserProfile />,
  },
  {
    path: "/main/edit-restaurant",
    element: <EditRestaurant />,
  },
  {
    path: ["/main/reservation", "/webapp/reservation"],
    element: <Reservation />,
  },
  {
    path: [
      "/main/create/reservation",
      "/webapp/create/reservation",
      "/main/update/reservation/:id",
      "/webapp/update/reservation/:id",
    ],
    element: <CreateReservation />,
  },
];
