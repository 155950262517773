import { LOCALES } from "../locales";

export default {
  [LOCALES.UZBEK]: {
    //Buttons
    "button.login": "Kirish",

    // Inputs
    "input.error.phone-length": "Telefon raqami qisqa!",
    "input.error.password<8": "Parol kamida 8 ta belgi bo'lishi kerak!",
    "input.required.password": "Parolni kiritishingiz majburiy!",
    "input.required.full_name": "Parolni kiritishingiz majburiy!",
    "input.required.role": "Rolni kiritishingiz majburiy!",
    "input.required.phone": "Telefonni kiritishingiz majburiy!",
    "input.required.name": "Nomini kiritishingiz majburiy!",
    "input.required.foods": "Ovqatlarni kiritishingiz majburiy!",
    "input.required.start_time": "Boshlanish vaqtini kiritishingiz majburiy!",
    "input.required.end_time": "Tugash vaqtni  kiritishingiz majburiy!",
    "input.required.customer_name": "Mijoz ismini kiritishingiz majburiy!",
    "input.required.total_people": "Odamlar sonini kiritishingiz majburiy!",

    //Notification messages
    "notification.success.delete": "Muvoffaqiyatli o'chirildi!",
    "notification.error.delete": "Oʻchirishda xatolik yuz berdi!",

    // Confirm
    "confirm.delete": "Rostdanam oʻchirib tashlamoqchimisiz!",

    //Common words
    "common.full_name": "To'liq Ism",
    "common.name": "Nomi",
    "common.role": "Rol",
    "common.phone": "Telefon",
    "common.search": "Qidirish",
    "common.password": "Parol",
    "common.delete": "O'chirish",
    "common.yes": "Ha",
    "common.no": "Yo'q",
    "common.foods": "Ovqatlar",
    "common.services": "Xizmatlar",
    "common.start_time": "Boshlanish vaqti",
    "common.end_time": "Tugash vaqti",
    "common.customer_name": "Mijoz ismi",
    "common.total_people": "Odamlar soni",
    "common.currency": "Pul birligi",
    "common.description": "Batafsil ma'lumot",
    "common.customer_phone": "Mijoz raqami",
    "common.total_budget": "Jami narxi",
    "common.individual_package": "Kishi boshiga narxi",
  },
};
 