import React from "react";
import JWTAuthAuthProvider from "./auth/JWTAuthProvider";
import { LanguageProvider, LOCALES } from "./i18n";
import { Button, ConfigProvider } from "antd";
import AppLayout from "./layouts";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider defaultLocale={LOCALES.UZBEK}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#ab5b0e",
            },
          }}
        >
          <JWTAuthAuthProvider>
            <AppLayout />
          </JWTAuthAuthProvider>
        </ConfigProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}
